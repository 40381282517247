import Vue from 'vue'
import { formatDate } from '@/lib/utils'
import {
  DiscountStatusType,
  DiscountRuleType,
} from '../../../shared/types/types'

export default Vue.extend({
  name: 'DiscountTableMixin',
  data() {
    return {
      isLoading: false,
      sort: [
        {
          createdAt: { order: 'desc' },
        },
      ],
    }
  },
  computed: {
    statusFilterOptions(): Record<string, string>[] {
      return [
        {
          label: 'Active',
          value: 'active' as DiscountStatusType,
        },
        {
          label: 'Scheduled',
          value: 'scheduled' as DiscountStatusType,
        },
        {
          label: 'Expired',
          value: 'expired' as DiscountStatusType,
        },
      ]
    },
    discountCodeTypeFilterOptions(): Record<string, string>[] {
      return [
        {
          label: 'Percentage',
          value: 'percentage' as DiscountRuleType,
        },
        {
          label: 'Fixed Amount',
          value: 'fixedAmount' as DiscountRuleType,
        },
        {
          label: 'Free Shipping',
          value: 'freeShipping' as DiscountRuleType,
        },
        {
          label: 'Buy X Get Y',
          value: 'buyXgetY' as DiscountRuleType,
        },
      ]
    },
    dropdownIcon() {
      return (active: boolean) => (active ? 'menu-up' : 'menu-down')
    },
  },
  methods: {
    handleSort(field: string, order: 'asc' | 'desc') {
      const sort: Record<string, any>[] = []
      switch (field) {
        case 'date': {
          sort.push({
            createdAt: { order },
          })
          break
        }
        case 'name': {
          sort.push({
            ['name.keyword']: { order },
          })
          break
        }
        default: {
          sort.push({
            [field]: { order },
          })
        }
      }

      if (JSON.stringify(sort) === JSON.stringify(this.sort)) {
        return
      }

      // @ts-ignore
      this.sort = sort
    },
    viewDiscount(discount: Record<string, any>) {
      this.$router.push(`/discounts/${discount.id}`)
    },
    setLoading(value: boolean) {
      this.isLoading = value
    },
    statusClass(status: DiscountStatusType) {
      if (status === 'active') {
        return 'is-success is-light'
      } else if (status === 'expired') {
        return 'is-danger is-light'
      } else {
        return 'is-light'
      }
    },
    formatUsage(input: { usage: number; usageLimit: number }) {
      if (input.usageLimit > 0) {
        return `${input.usage}/${input.usageLimit} used`
      }
      return `${input.usage} used`
    },
    formatDates(discount: Record<string, any>) {
      let result = formatDate(new Date(discount.startsAt))
      if (discount.endsAt) {
        result = `${result} - ${formatDate(new Date(discount.endsAt))}`
      }
      return result
    },
  },
})
