
























































































































import Vue, { PropType } from 'vue'
import debounce from 'just-debounce-it'
import mixins from 'vue-typed-mixins'
import { getElasticSearchClient } from '@/services/elasticSearch'
import DiscountsTableMixin from '@/mixins/DiscountsTable.mixin'
import QueryParamsMixin from '@/mixins/QueryParams.mixin'
import FilterDropdown from '../shared/FilterDropdown.vue'
import MoreFilters from '@/components/discounts/MoreFilters.vue'
import { DiscountType, CurrencyEnum } from '../../../../shared/types/types'
import {
  deleteDiscounts,
  enableDiscounts,
  disableDiscounts,
} from '@/lib/discount'
import { errorMessage } from '../../constants/message'

interface Row {
  id: string
  name: string
  status: string
  ruleType: string
  type: DiscountType
  usage: number
  usageLimit: number
  summary: string
  startsAt: Date
  endsAt: Date
  createdAt: Date
  updatedAt: Date
}

export default mixins(DiscountsTableMixin, QueryParamsMixin).extend({
  name: 'DiscountTable',
  components: {
    FilterDropdown,
    MoreFilters,
  },
  props: {
    discountType: {
      type: String as PropType<DiscountType>,
      required: true,
    },
  },
  data() {
    return {
      searchInput: '',
      searchResult: [] as Record<string, any>,
      status: [] as string[],
      discountCodeType: [] as string[],
      dateRange: [] as string[],
      timeUsed: {
        from: '',
        to: '',
        equal: '',
        notEqual: '',
      },
      booleanFilters: [] as string[],
      selectedDiscounts: [] as Row[],
      showMoreFilters: false as boolean,
      temporaryDeletedDiscountIds: [] as string[],
    }
  },
  computed: {
    query(): Record<string, any> {
      const query: Record<string, any> = {
        query: {
          bool: {
            filter: [
              {
                term: {
                  type: this.discountType,
                },
              },
              {
                term: {
                  currency: CurrencyEnum.Usd,
                },
              },
            ],
            must: [],
            must_not: [],
          },
        },
        track_total_hits: true,
        sort: this.sort,
        size: this.itemsPerPage,
        from: (this.currentPage - 1) * this.itemsPerPage,
      }

      if (this.search && this.search.length > 0) {
        query.query.bool.filter.push({
          term: { name: this.search },
        })
      }

      if (this.status.length > 0) {
        query.query.bool.filter.push({
          terms: { status: this.status },
        })
      }

      if (this.discountCodeType.length > 0) {
        query.query.bool.filter.push({
          terms: { ruleType: this.discountCodeType },
        })
      }

      if (this.timeUsed.from.length > 0) {
        query.query.bool.must.push({
          range: {
            usage: {
              gte: this.timeUsed.from,
            },
          },
        })
      }

      if (this.timeUsed.to.length > 0) {
        query.query.bool.must.push({
          range: {
            usage: {
              lte: this.timeUsed.to,
            },
          },
        })
      }

      if (this.timeUsed.equal.length > 0) {
        query.query.bool.must.push({
          term: {
            usage: this.timeUsed.equal,
          },
        })
      }

      if (this.timeUsed.notEqual.length > 0) {
        query.query.bool.must_not.push({
          term: {
            usage: this.timeUsed.notEqual,
          },
        })
      }

      if (this.dateRange.length > 0) {
        query.query.bool.filter.push({
          range: {
            startsAt: {
              format: 'strict_date_optional_time',
              gte: this.dateRange[0],
              lte: this.dateRange[1],
            },
          },
        })
      }

      if (
        query.query.bool.filter.length === 0 &&
        query.query.bool.must.length === 0 &&
        query.query.bool.must_not.length === 0
      ) {
        query.query = {
          match_all: {},
        }
      }

      return query
    },
    nbHits(): number {
      return this.searchResult && this.searchResult.hits
        ? this.searchResult.hits.total.value
        : 0
    },
    rows(): Row[] {
      if (!this.searchResult || this.searchResult.length === 0) {
        return []
      }
      return this.searchResult.hits.hits.map((h: { _source: Row }) => h._source)
    },
    filterPlaceholder(): string {
      return `Filter discount ${this.discountType}...`
    },
  },
  watch: {
    searchInput: {
      immediate: true,
      handler: debounce(function () {
        //@ts-ignore
        this.search = this.searchInput.trim()
      }, 500),
    },
    query: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    getSelectedDiscountIds(): string[] {
      if (this.selectedDiscounts?.length > 0) {
        return this.selectedDiscounts.map((discount) => discount.id)
      } else {
        throw new Error(errorMessage.EMPTY_BULK_SELECTION)
      }
    },
    debouncedSearchInput: debounce(function (this: any, value: string) {
      this.search = value
    }, 500),
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post(
        '/discounts/_search',
        this.query
      )
      this.searchResult = data
    },
    async deleteDiscounts(): Promise<void> {
      try {
        const discountIds = this.getSelectedDiscountIds()
        await deleteDiscounts(discountIds)
        this.temporaryDeletedDiscountIds.push(...discountIds)
        this.selectedDiscounts = []
      } catch (e) {
        this.$buefy.toast.open({
          message: e instanceof Error ? e.message : 'Something went wrong',
          position: 'is-bottom',
          type: 'is-danger',
        })
      }
    },
    async enableDiscounts(): Promise<void> {
      try {
        const discountIds = this.getSelectedDiscountIds()
        await enableDiscounts(discountIds)
        await this.fetchData()
      } catch (e) {
        this.$buefy.toast.open({
          message: e instanceof Error ? e.message : 'Something went wrong',
          position: 'is-bottom',
          type: 'is-danger',
        })
      }
    },
    async disableDiscounts(): Promise<void> {
      try {
        const discountIds = this.getSelectedDiscountIds()
        await disableDiscounts(discountIds)
        await this.fetchData()
      } catch (e) {
        this.$buefy.toast.open({
          message: e instanceof Error ? e.message : 'Something went wrong',
          position: 'is-bottom',
          type: 'is-danger',
        })
      }
    },
    isRowSelected(a: any, b: any): boolean {
      return a.id === b.id
    },
    updateFilter(filterName: string, filterValue: any): void {
      switch (filterName) {
        case 'timeUsedFrom': {
          Vue.set(this.timeUsed, 'from', filterValue)
          break
        }
        case 'timeUsedTo': {
          Vue.set(this.timeUsed, 'to', filterValue)
          break
        }
        case 'timeUsedEqual': {
          Vue.set(this.timeUsed, 'equal', filterValue)
          break
        }
        case 'timeUsedNotEqual': {
          Vue.set(this.timeUsed, 'notEqual', filterValue)
          break
        }
        default: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Vue.set(this, filterName, filterValue)
          break
        }
      }
    },
    openMoreFilters(): void {
      this.showMoreFilters = true

      this.$emit('preventScroll', true)
    },
    closeMoreFilters(): void {
      this.showMoreFilters = false

      this.$emit('preventScroll', false)
    },
    resetFilters(): void {
      this.status = []
      this.discountCodeType = []
      this.dateRange = []
      this.resetTimeUsed()
    },
    resetTimeUsed() {
      Vue.set(this, 'timeUsed', {
        from: '',
        to: '',
        equal: '',
        notEqual: '',
      })
    },
  },
  created() {
    this.resetTimeUsed()
  },
})
