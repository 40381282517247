
























import Vue from 'vue'
import DiscountTable from '../../components/discounts/DiscountTable.vue'
import CreateDiscountModal from '../../components/discounts/CreateDiscountModal.vue'
import { DiscountType } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'Discounts',
  components: {
    DiscountTable,
  },
  data() {
    return {
      activeTab: 0,
    }
  },
  computed: {
    discountCode(): DiscountType {
      return DiscountType.Code
    },
    discountAutomatic(): DiscountType {
      return DiscountType.Automatic
    },
  },
  watch: {
    activeTab() {
      let instance =
        this.activeTab === 0
          ? this.$refs.codeDiscountTable
          : this.$refs.automaticDiscountTable
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      instance.updateQueryString()
    },
  },
  methods: {
    openCreateDiscountsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CreateDiscountModal,
        hasModalCard: true,
        trapFocus: true,
      })
    },
  },
})
