























import Vue from 'vue'
import ModalHeader from '@/components/shared/ModalHeader.vue'

export default Vue.extend({
  name: 'CreateDiscountModal',
  components: {
    ModalHeader,
  },
  data() {
    return {
      isLoading: false,
      ordersSelection: 'all',
    }
  },
  methods: {
    openDiscountCode() {
      this.$router.push(`/discounts/code/new`)
      this.$emit('close')
    },
    openDiscountAutomatic() {
      this.$router.push(`/discounts/automatic/new`)
      this.$emit('close')
    },
  },
})
