



























































import Vue, { PropType } from 'vue'
import Separator from '../shared/Separator.vue'

export default Vue.extend({
  name: 'MoreFilters',
  components: {
    Separator,
  },
  props: {
    dateRange: {
      type: Array as PropType<number[]>,
      required: true,
    },
    timeUsed: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    from: {
      get(): string {
        return this.timeUsed.from
      },
      set(value: string) {
        this.$emit('updateFilter', 'timeUsedFrom', value)
      },
    },
    to: {
      get(): string {
        return this.timeUsed.to
      },
      set(value: string) {
        this.$emit('updateFilter', 'timeUsedTo', value)
      },
    },
    equal: {
      get(): string {
        return this.timeUsed.equal
      },
      set(value: string) {
        this.$emit('updateFilter', 'timeUsedEqual', value)
      },
    },
    notEqual: {
      get(): string {
        return this.timeUsed.notEqual
      },
      set(value: string) {
        this.$emit('updateFilter', 'timeUsedNotEqual', value)
      },
    },
    dateRangeValue: {
      get(): number[] {
        return this.dateRange
      },
      set(value: Date[]) {
        this.$emit('updateFilter', 'dateRange', value)
      },
    },
    visibilityClasses(): Record<string, boolean> | null {
      if (this.open) return null

      return {
        'translate-x-full': true,
      }
    },
  },
  methods: {
    getIcon(isOpen: boolean) {
      return isOpen ? 'chevron-up' : 'chevron-down'
    },
    resetFilters() {
      this.$emit('resetFilters')
    },
    close() {
      this.$emit('close')
    },
  },
})
